$(".back-image").each(function() {
    getBackground = $(this).attr('data-image');
    $(this).css('background-image', 'url(' + getBackground + ')');
});

$('#homeSlider').owlCarousel({
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true
});

$("#homeSlider .links a").each(function () {
    getBackground = $(this).attr('data-bg');
    getColor = $(this).attr('data-color');
    getBorderColor = $(this).attr('data-border-color');
    $(this).css('background-color', getBackground)
    $(this).css('color', getColor)
    $(this).css('border-color', getBorderColor)
});



$('#company-events-slider').owlCarousel({
    dots: true,
    loop: true,
    items: 4,
    margin: 30,
    nav: false,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 4
        }

    }
});

$('#scroll-up').click(function(){
    $('html, body').animate({scrollTop : 0},800);
    return false;
});


if ($(".company_gallery").length > 0) {
    $(".company_gallery").lightGallery();
}

if ($(".company_menu_gallery").length > 0) {
    $(".company_menu_gallery").lightGallery();
}